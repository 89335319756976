module.exports = {
  siteName: 'Appoint',
  appointTextWhite: './AppointWhiteTransparentNoIcon.png',
  appointTextBlack: './AppointBlackTransparentNoIcon.png',
  appointCA_WhiteText: './ApointCA_WhiteTextTransparent.png',
  appointCA_BlackText: './ApointCA_BlackTextTransparent.png',

  logoPath: './FinalLogoHighRes.png',
  logoWithName: '/logoWithWhiteName.png',
  logoWithBlackName: '/logoWithBlackName.png',

  deafaultUserPath: './DefaultUser.png',
  addressPin: './AddressPin.png',
  headerImage: './SampleHeaderImage.png',
  tempHeaderImage: './TempHeaderImage.jpg',
  customizedReports: './Reporting.png',
  easyOfUse: './EasyOfUse.png',
  algorithm: './AutomaticScheduling.png',
  constantlyEvolving: './constantlyEvolving.png',
  informationIcon: './informationIcon.png',
  trashCanIcon: './TrashCanIcon.png',
  editIcon: './EditIcon2.png',
  fileUploadIcon: './FileUpload.png',
  cancelIcon: './CancelIcon.png',
  HamburgerIcon: './HamburgerIcon.png',
  saveIcon: './SaveIcon.png',
  personIcon: './personIcon.png',
  homePageHeader: './HomePageHeader4.jpg',
  homePageBackgroundOne: './HomePageBackgroundOne.png',
  spinnerIcon: './SpinnerIcon.gif',
  documentIcon: './DocumentIcon.png',
  termsOfUseIcon: './TermsOfUse.png',
  duplicateIcon: './duplicatePaper.png',
  stackedPaperIcon: './stackedPaper.png',
  veryBadEmoji: './veryBadEmoji.png',
  badEmoji: './badEmoji.png',
  neutralEmoji: './neutralEmoji.png',
  goodEmoji: './goodEmoji.png',
  veryGoodEmoji: './veryGoodEmoji.png',
  transparentImage: './transparentImage.png',
  hospitalIcon: './Hospital.png',
  calendarIcon: './calendarIcon.png',
  weekdayIcon: './weekdayIcon.png',
  professorIcon: './professorIcon.png',
  locationIcon: './locationIcon.png',
  clockIcon: './clockIcon.png',
  clinicaldaysIcon: './clinicaldaysIcon.png',
  redChecklistIcon: './redChecklistIcon.webp',
  backButton: './backButton.webp',

  // apiPrefix: '/api/v1',
  fixedHeader: true, // sticky primary layout header

  headshotHailey: './HeadshotHailey2.png',
  headshotHunter: './HeadshotHunter2.png',
  headshotMike: './HeadshotMike2.png',
  headshotJon: './HeadshotJon2.png',
  headshotMichelle: './HeadshotMichelle2.png',
  OurTeamBackground: './OurTeamBackground.png',

  SquareBorder1: '/square-border1.png', 

  /* Layout configuration, specify which layout to use for route. */
  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/],
    },
  ],
}
  