/// Probably won't go back to regex for dates, but useful to keep a working one: dobFormat =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

export function isValidAge(dateString){
  try {
    const dateGiven = new Date(dateString)
    const today = new Date()
  
    const yearsDifference = today.getFullYear() - dateGiven.getFullYear()
    const isOver12Years = yearsDifference > 12;
  
    return isOver12Years
  }
  catch{
    return false
  }
}

export function isValidStudentID(studentIdString){
  const idLength = String(studentIdString).length
  if (idLength < 2){
    return ([false, 'Student ID length too short'])
  }
  if (idLength > 15){
    return ([false, 'Student ID length too long'])
  }
  return ([true, ''])
}

export function isValidLicensePlateNum(licensePlateString){
  if (licensePlateString.length > 12){
    return ([false, 'Licence Plate length too long'])
  }
  return true
}

export function isValidPhone(phoneString){
  const phoneFormat = /^\+\d{1,3}(\s?\(\d{3}\)\s?|\s?)\d{3}(\s?\-\s?\d{4})?$/ // Requires 1-3 then 3 then 3 then 4 digits exactly
  console.log(phoneString.length)
  console.log(phoneString)

  if (phoneFormat.test(phoneString)){
    return true
  }
  return false

}

export function isValidSchoolYear(schoolYearString){
  const studentYearOptions = [ "freshman", "sophomore", "junior", "senior", "other" ]
  schoolYearString = schoolYearString.toLowerCase().trim()
  
  if (studentYearOptions.includes(schoolYearString)){
    return true
  }else{
    return false
  }

}

export function isValidEmail(emailString){
  // need to check for @ symbol somewhere in string
  let atCheck = emailString.includes('@')

  // then check for .com or .edu or .net or .gov ending
  let domains = ['.com', '.edu', '.net', '.gov']
  let domainCheck = domains.includes(emailString.slice( emailString.length - 4, emailString.length))

  return (atCheck && domainCheck)
}

export function isValidName(nameString){
  // no punctuation, except maybe a period
  let nameFormat = /^(?!.*\.{2})[A-Za-z.]+(?: [A-Za-z.]+)*$/
  let result = nameFormat.test(nameString)
  return result
}

export function isValidAddress(address){
  const addressID = address.placeId

  if (addressID == null || addressID == '' || addressID == ' '){
    return false
  }
  return true
  
}
