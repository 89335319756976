import { makeStyles } from '@mui/styles';
import React, { Component, useState } from 'react'
import config from '../../../config';
import './Footer.css'
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-scroll';
import copyToClipboard from '../../../functions/copyToClipboard';

const useStyles = makeStyles(() => ({
  features: {
    fontFamily: 'Glac',
    fontWeight:'Regular',
    color: 'white',
    fontSize:'18px',
    // margin:'60px'
  },
  logo: {
    backgroundColor: 'transparent',
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: '500px',
    maxHeight: '200px',
    scale: 1.3,

    marginLeft: '45px',
    marginRight: '0px',
    marginTop:'45px',
    marginBottom:'45px',
    alignItems:'center'
  },
  logoMobile: {
    backgroundColor: 'transparent',
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: '250px',
    maxHeight: '100px',

    marginLeft: '15px',
    marginRight: '15px',
    marginTop:'15px',
    marginBottom:'15px',
    alignItems:'center'
  },
  
}));
export default function Footer(props) {
  const classes = useStyles()  

  /* Using useHistory imported above to redirect to links */
  const history = useHistory();
  const defaultHome = () => history.push('/');
  const home = () => {
      if(window.location.href.split("/").at(-1) === ""){
          window.scroll({top: 0,  behavior: "smooth" })
      } else {
          console.log("We were not on the home page")
      }
      history.push('/')
  }
  const dashboard = () => history.push('/dashboard');
  const login = () => history.push('/login');  
  const LinkedIn = () => window.open("https://www.linkedin.com/company/appointgrp/", '_blank');

  const email = 'ContactUs@AppointGrp.com'
  const [emailCopied, setEmailCopied] = useState(false)

  function copyEmailToClipboard() {
    copyToClipboard(email)
      .then(succ => {
        setEmailCopied(true);
        setTimeout(() => setEmailCopied(false), 5000);
      })
    try {
      
        
    } catch (error) {
      console.error('Failed to copy:', error);
      // do nothing
    }
  }
  
  return(
    <div className='flex-container center' style={{flexDirection: (isMobile ? 'column' : 'row'),
                                            height: (isMobile ? '1000px': '')}}>
        <div className='sub-container'>
          <img alt="logo" src={config.appointCA_WhiteText} className={(isMobile ? classes.logoMobile : classes.logo)} />
        </div>

        <div className='textBodyContainer'>
          <div className='textHead'>Menu</div> 
          <br></br>
          <div className='textBody homepageFooterLink' onClick={home}>Home</div>
          <div className='textBody homepageFooterLink' onClick={dashboard}>Dashboard</div>
          <div className='textBody homepageFooterLink' onClick={login}>Sign In</div>
        </div>
        <div className='textBodyContainer'>
          <div className='textHead'>Connect</div> 
          <br></br>
          <div className='textBody homepageFooterLink' onClick={LinkedIn}>LinkedIn</div>
          <div className='textBody homepageFooterLink' onClick={copyEmailToClipboard}>{email}</div>
          {emailCopied ?
            <div className='textBodyEmail copiedToClipboardMessage'>Copied to Clipboard!</div>
            :
            <div>&nbsp;</div>
          }
        </div>
        <div className='textBodyContainer'>
          <div className={classes.features}>
            <b>Features</b> <br></br> <br></br>

            {/* Easy-to-use Platform <br></br>
            Automatic Scheduling Algorithm <br></br>
            Customizable Reports and Exports */}

            <div className='textBody'><Link className='homepageFooterLink' to='keyPoint1' offset={-150} spy={true} smooth={true} duration={1000} >Easy-to-use Platform</Link></div>
            <div className='textBody'><Link className='homepageFooterLink' to='keyPoint2' offset={-150} spy={true} smooth={true} duration={1000} >Automatic Scheduling Algorithm</Link></div>
            <div className='textBody'><Link className='homepageFooterLink' to='keyPoint3' offset={-150} spy={true} smooth={true} duration={1000} >Customizable Reports and Exports</Link></div>

          </div>
        </div>
    </div>
  )
}
