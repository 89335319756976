import { useHistory } from 'react-router-dom';
import config from '../../config'

const altTag = 'Appoint logo'

export function AppointLogo({ compact, blackVariant, height, style }) {
  const history = useHistory();
  let standardHeight = '65px'
  let color = 'white'
  let logo = config.logoWithName
  if (height) {
    standardHeight = height
  }

  if (blackVariant) {
    color = '#364a5e'
    logo = config.appointCA_BlackText
  }

  if (compact) {
      return <img alt={altTag} src={config.logoPath} style={style} className="logo" />
  }

  return (
    <div className='navBar_Brand' onClick={() => history.push('/')}>
      <img alt={altTag} src={logo} style={{height: standardHeight}}/>
    </div>
  )
}