import React from 'react';
import { isMobile } from 'react-device-detect';
import config from '../../../config';
import '../Content/Content.css';

import OurTeamBackground from "../OurTeamBackground.png";

import Carousel3d from "../../../../../client/src/components/Home/Content/Carousel3d.js";
import CarouselCard from "../../../../../client/src/components/Home/Content/CarouselCard";


export default function OurTeamCarousel() {

  return (

    <div
      className="HomePage_OurTeam_Main"
      style={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundImage: `url(${OurTeamBackground})`,
        backgroundPosition: '100%',
        backgroundSize: 'cover', 
        height: '1200px',
        marginTop:'100px'
      }}>

      <div className='Center OurTeam_Title'>
          Our Team
      </div>

      <div className='OurTeam_SubTitle'>
          The creators and innovators behind 

          <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
            {<img className='ourTeam_AppointLogo' src={config.appointTextWhite}></img>}
          </div>

      </div>        

      <div style={{ width: '100vw', height: '875px', marginTop: isMobile ? '100px' : ''}}> 
        <Carousel3d
          cards={[
            {
              key: 1,
              content: <CarouselCard title="Hailey Altobelli" image={config.headshotHailey} position="Chief Marketing Officer" 
              text="A UConn MIS BS Graduate and branding expert who's committed to connecting you to a program that fits your needs." />,
            },
            {
              key: 2,
              content: <CarouselCard title="Hunter Bowden" image={config.headshotHunter} position="Chief Operating Officer" 
              text="A UConn MIS BS and MS BAPM graduate, driven by analytics and data science to optimize performance, efficiency, and results." />,
            },

            {
              key: 3,
              content: <CarouselCard title="Mike Greco" image={config.headshotMike} position="Chief Technology Officer" 
              text="A UConn MIS BS Graduate and software engineer who brings functionality and performance you can trust." />,
            },
            {
              key: 4,
              content: <CarouselCard title="Jon Moore" image={config.headshotJon} position="Chief Executive Officer" 
              text="A UConn Professor with over 20+ years in the Database Systems World who is committed to leading the charge of bringing this program nationwide." />,
            },
            {
              key: 5,
              content:
                <CarouselCard title="Michelle Saglimbene" image={config.headshotMichelle} position="Chief Industry Officer"  
              text="An accomplished nurse executive with expertise in clinical placement and enhancing student experiences, driving growth in healthcare professionals." />,
            },
          ]}
          offset={2}
        />
      </div>
    </div>
  );
}