import axios from 'axios';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosURL } from '../../axiosURL_Front';
import UserContext from '../../context/UserContext';
import './Register.css';

import { FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MuiPhoneNumber from 'material-ui-phone-number';
import PlatformStyles from '../../PlatformStyles';
import AddressAutoComplete from '../misc/AddressAutoComplete';
import { Alert } from '../misc/Alert';
import { isValidDate } from '../misc/isValidDate';

import {isValidName, isValidAge, isValidAddress, isValidStudentID, isValidLicensePlateNum, isValidPhone, isValidSchoolYear, isValidEmail} from '../misc/userInfoValidation.js'

// https://www.youtube.com/watch?v=IDUBHxn5nAQ

const useStyles = makeStyles(() => ({
    formControlRoot: {
      display: "flex",
      alignItems: "center",
      width: "308px",
      // float: 'left',
      margin: '20px 0px 20px 6px',
      // marginBottom: '20px',
      flexWrap: "wrap",
      paddingTop: '15px',
      // flexDirection: "column",
      height: '20px !important',
      padding: 4,
      borderRadius:'4px',
      "&> div.container": {
          
          gap: "6px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
      },
      "& > div.container > span": {
          padding: "1px 3px",
          borderRadius: "4px",
      },
    },
    FormInput: {
        width: '100%',
        float: 'left',
        display: 'relative',
        height: '30px',
        marginTop: '-18px',
        marginRight: '68px'
    },
    Input: {
        borderRadius: PlatformStyles.BorderRadius,
        border: 'none',
        width: '300px',
    }
    
  }));

export default function Activate() {
  const history = useHistory();
  const classes = useStyles();

  const [token, setToken] = useState("")
  const [accountType, setAccountType] = useState('')
  const [isExpired, setIsExpired] = useState(false)
  const {userData, setUserData} = useContext(UserContext)

  let [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')  
  const [errorMessage, setErrorMessage] = useState(null)

  let [schoolYear, setSchoolYear] = useState('')
  
  let [dob, setDOB] = useState(null)

  let [licensePlateNum, setLicensePlateNum] = useState('')

  let [phone, setPhone] = useState('')

  let [studentID, setStudentID] = useState('')

  let [mailingAddress, setMailingAddress] = useState(null)
  let [showPassword, setShowPassword] = useState(false)

  const studentYearOptions = [ "Freshman", "Sophomore", "Junior", "Senior", "Other" ]

  ;

  // Gets temp token that was sent to them in email
  useLayoutEffect(() =>{
    const token = window.location.href.split('/')
    setToken(token[token.length - 1])

    const packagedData = {
        token
    }

    axios.post(axiosURL + '/users/accountTypeByToken', packagedData)
    .then(res => {
      if(res.data.success === true)  {
        setAccountType(res.data.accountType)
      } else {
        setErrorMessage(res.data.message)
        console.log('error getting account type by token')
        setIsExpired(true)
      }
    })    
  }, [])
  
  const submit = async (e) => {
    e.preventDefault();

    if(isExpired){
        return
    }

    schoolYear = schoolYear.trim()
    studentID = studentID.trim()
    licensePlateNum = licensePlateNum.trim()
    phone = phone.trim()
    schoolYear = schoolYear.trim()
    

    if (password != passwordCheck){
        setErrorMessage('Password must match')
        return;
    } 

    if (password == '') {
        setErrorMessage('Password is required')
        return;
    }

    let activationInfo = {}
    if(accountType == 'student'){

      if (!isValidSchoolYear(schoolYear)){
        setErrorMessage('Error with School Year')
        return;
      }

      let validatedIdResult = isValidStudentID(studentID)
      if (!validatedIdResult[0]){
        setErrorMessage(validatedIdResult[1]) // Determines what message is returned
        console.log('error: validatedIdResult[0]', validatedIdResult[0])
        console.log('error: validatedIdResult[1]', validatedIdResult[1])
        return;
      }

      if (!isValidAge(dob)){
        setErrorMessage('Please select a valid Date of Birth')
        return
      }
      try{
        mailingAddress.address = mailingAddress.address.trim()
      }
      catch (error){
        setErrorMessage('Please select an address from the drop down')
        console.log('error: ' , error)
        return
      }
      if (!isValidAddress(mailingAddress)){
        setErrorMessage('Please select an address from the drop down')
        return
      }

      if (!isValidLicensePlateNum(licensePlateNum)){
        setErrorMessage('License Plate Number is too long') 
        return;        
      }

      if (!isValidPhone(phone)){
        setErrorMessage('Phone Number is formatted incorrectly')
        return;
      }
  
      activationInfo = {
          type: 'student',
          temporarytoken: token,
          password: password,
          schoolYear,
          studentID,
          dateOfBirth: dob,
          licensePlate: licensePlateNum,
          phone,
          address: mailingAddress,
      }
    } else {
        activationInfo = {
            temporarytoken: token,
            password: password,
            type: 'administrator'
        }
    }

    const activationRes = await axios.post(axiosURL + '/users/activate', activationInfo)

    if (activationRes.data.success === true){
        const user = {
            email: activationRes.data.email,
            password: password
        }
        
        const loginRes = await axios.post(axiosURL + '/users/login', user)
        
        if(loginRes.data.msg){
            setErrorMessage(loginRes.data.msg)
            console.log('error loginRes.data.msg', loginRes.data.msg)
        } else{
            setUserData({
                token: loginRes.data.token,
                user: loginRes.data.user
            })
            // console.log(userData)
            /* stores information in browser */
            localStorage.setItem('auth-token', loginRes.data.token)
            history.push('/dashboard')
        }
    } else {
        setErrorMessage(activationRes.data.message)
    }
  }

  return(
      <div className='body'>
          <h3 className='title'>Account Activation</h3>
          <form onSubmit={submit} className='form'>              
              {accountType == 'student' ?
                <>
                    {/* SCHOOL YEAR */}
                    <FormControl fullWidth className={classes.Input}>
                        <TextField 
                            style={{float: 'left'}}
                            variant='outlined'
                            select 
                            value={schoolYear}
                            disabled={isExpired}
                            required
                            label={"School Year"}
                            id={'School Year'}
                            onChange={(e) => {setSchoolYear(e.target.value)}}
                            fullWidth
                          >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {studentYearOptions != null &&
                              studentYearOptions.map(option => {
                                return <MenuItem value={option}>{option}</MenuItem>
                              })
                            }
                        </TextField>
                    </FormControl>

                    <TextField 
                        value={studentID}
                        disabled={isExpired}
                        onChange={(e) => setStudentID(e.target.value)}
                        style={{marginTop:'10px'}}
                        className={classes.Input}
                        required
                        id="Student Number"
                        label="Student Number"
                        variant='outlined'
                        inputProps={{
                          autoComplete: 'off',
                        }}
                    />

                    {/* DATE OF BIRTH */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="Date of Birth"
                        label="Date of Birth"
                        disabled={isExpired}
                        value={dob}
                        onChange={setDOB}
                        className={classes.Input}
                        renderInput={(params) => 
                          <TextField 
                            {...params}
                            variant='outlined'
                            className={classes.Input}
                            style={{marginTop:'10px'}}
                            required
                          />
                        }
                      />
                    </LocalizationProvider>

                    {/* ADDRESS */}
                    <AddressAutoComplete value={mailingAddress} disabled={isExpired} style={{marginTop:'10px'}} onChange={setMailingAddress} />

                    {/* License Plate Number: we took this out */}
                    {/* <TextField 
                        value={licensePlateNum}
                        disabled={isExpired}
                        onChange={(e) => setLicensePlateNum(e.target.value)}
                        style={{marginTop:'10px'}}
                        className={classes.Input}
                        id="License Plate #"
                        label="License Plate #" 
                        variant='outlined'
                    /> */}

                    {/* PHONE NUMBER */}
                    <MuiPhoneNumber
                      label="Phone Number"
                      fullWidth
                      defaultCountry={"us"}
                      variant="outlined"
                      style={{marginTop:'10px'}}
                      onChange={setPhone}
                      disableAreaCodes
                      countryCodeEditable={false}
                      value={phone}
                    />

                    {/* SPACING */}
                    <div>
                        <br></br>
                    </div>
                </>
                :
                 <></>
              }

              {/* PASSWORD */}
            <TextField 
                value={password}
                disabled={isExpired}
                onChange={(e) => setPassword(e.target.value)}
                style={{marginTop:'10px'}}
                required
                className={classes.Input}
                id="Password"
                label="Password" 
                variant='outlined'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            disabled={isExpired}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
            /> 

              {/* CONFIRM PASSWORD */}
            <TextField 
                value={passwordCheck}
                disabled={isExpired}
                onChange={(e) => setPasswordCheck(e.target.value)}
                style={{marginTop:'10px', marginBottom:'10px'}}
                required
                className={classes.Input}
                id="Confirm Password"
                label="Confirm Password"
                variant='outlined' 
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            disabled={isExpired}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
            />

              {/* SUBMIT BUTTON */}
              <div>
                  <input type="submit" disabled={isExpired} value="Submit" style={{margin: '15px 0px'}} className="btn btn-primary" />
              </div>
              {errorMessage !== null &&
              <Alert className={classes.ErrorMessage} severity="error">{errorMessage}</Alert>
              }
          </form>
      </div>
  )
}