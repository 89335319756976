import { EditOutlined } from '@ant-design/icons'
import { makeStyles } from '@mui/styles';
import axios from 'axios'
import Collapse from 'rc-collapse'
import React, { useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { axiosURL } from '../../axiosURL_Front'
import config from '../../config'
import UserContext from '../../context/UserContext'
import calculateAge from '../../functions/calculateAge'
import checkLoggedIn from '../../functions/checkLoggedIn'
import convertTime from '../../functions/convertTime'
import formatPhoneNumber from '../../functions/formatPhoneNumber'
import { getNA } from '../../functions/getNA'
import { getAuthHeader } from '../../functions/getToken'
import { notNullString } from '../../functions/notNullString'
import { isStudent, isAdministrator, isSuperUser} from '../../functions/UserTypeChecks'
import PlatformStyles from '../../PlatformStyles'
import { AppointTransparentButton, AppointBlueButton, AppointGreyButton, AppointRedButton } from '../../utils/AppointButton'
import { AppointTileWrapper } from '../../utils/AppointTileWrapper'
import CropEasy from '../ImageSelector.js/CropEasy'
import Loading from '../Loading/Loading'
import { StudentFilePills } from '../misc/FilePills'
import { ProfilePicture } from '../misc/ProfilePicture'
import { notify } from '../Notification/notify'
import StudentFile from '../StudentFile/StudentFile'
import Table from '../Users/Table'
import './UserProfile.css'
import { TextField, IconButton, InputAdornment } from "@mui/material"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AddressAutocomplete from '../misc/AddressAutoComplete';
import { socket } from '../../socket'
import MuiPhoneNumber from 'material-ui-phone-number';
import {isValidName, isValidAge, isValidAddress, isValidStudentID, isValidLicensePlateNum, isValidPhone, isValidSchoolYear, isValidEmail} from '../../components/misc/userInfoValidation.js'
import { Alert } from '../../components/misc/Alert'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import belongsToSameSchool from '../../functions/belongsToSameSchool';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from "@mui/material";


const useStyles = makeStyles(() => ({
  LegalNotice: {
    fontSize: '12px',
    width: '95%',
    margin: 'auto',
  },
  EditIconWrapper: {
    height: '30px',
    width: '30px',
    fontSize: '20px',
    borderRadius: '100% !important',
  },
  EditIcon: {
    width: '30px',
    height: '30px',
    backgroundColor: PlatformStyles.LogoLightBlue,
    color: 'black',
    alignItems: 'center',
    borderRadius: '100px',
    lineHeight: '25px',
    fontSize: '17px',
    position: 'relative',
    // left: '100px'
    paddingLeft: '5.5px',
    right: '17px',
    top: '-70px',
    '&:hover': {
      backgroundColor: PlatformStyles.LogoLightBlueHover,
      boxShadow: PlatformStyles.BoxShadowDark,
    },
  },
  Left: {
    width: '49.2%',
    margin: '5px 5px 5px 0px !important'
  },
  Right: {
    width: '49.2%',
    margin: '5px 0px 5px 5px !important',
  },
  mobileMargin: {
    margin: '10px 0px 10px 0px !important',
    width: '100%'
  },

  EditIconAbout: {
    width: '30px',
    height: '30px',
    backgroundColor: PlatformStyles.LogoLightBlue,
    color: 'black',
    alignItems: 'center',
    borderRadius: '100px',
    lineHeight: '25px',
    fontSize: '17px',
    position: 'relative',
    left: '12px',
    top: '-3px',
    '&:hover': {
      backgroundColor: PlatformStyles.LogoLightBlueHover,
      boxShadow: PlatformStyles.BoxShadowDark,
    },
  },
}))

export default function Users({ adjustNavProfilePhoto }) {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const { userData, setUserData, isMobile } = useContext(UserContext)
  const classes = useStyles()

  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams() // Passed in through URL
  let [userID, setUserID] = useState(id)
  let [user, setUser] = useState()

  const [aboutBoolean, setAboutBoolean] = useState(true)
  const [scheduleBoolean, setScheduleBoolean] = useState(false)
  const [fileId, setFileId] = useState(null)

  const [studentSchedules, setStudentSchedules] = useState([])
  const bottomBorderColor = 'solid 2px #474f7a'
  const [aboutBorder, setAboutBorder] = useState(bottomBorderColor)
  const [schedulBorder, setScheduleBorder] = useState('')
  const [openProfilePhotoSelector, setOpenProfilePhotoSelector] = useState(false)

  const defaultProfilePhoto = config.deafaultUserPath
  const [profilePictureName, setProfilePictureName] = useState(null) // Show the default user while the profile photo is loading
  const [showEditIcon, setShowEditIcon] = useState(false)

  const [personalInfoModal, setPersonalInfoModal] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    socket.removeListener('EditUserInfoRequestEvent')
    socket.on('EditUserInfoRequestEvent', (event) => {
      if (event.success) {
        setUser(event.data)
        notify('success', event.message)
      } else {
        notify('error', event.message)
      }
    })
  }, [user])

  useLayoutEffect(() => {
    // Makes sure the user is loggedin
    let loggedInCheck = false
    let hasAccessRights = false

    if (checkLoggedIn(localStorage.getItem('auth-token'))) {
      loggedInCheck = true
    } else {
      history.push('/login')
    }

    // Student's can't have access to other student's pages
    if (isStudent(userData.user.accountType) && userID.toString() !== userData.user.id.toString()) {
      history.push('/dashboard')
      return
    } 
    
    /////////////////////// NEED TO CHECK IF COORDINATOR IS RESPONSIBLE FOR THIS STUDENT
    // if (isAdministrator(userData.user.accountType) || isSuperUser(userData.user.accountType)){

    let superUserStatus = isSuperUser(userData.user.accountType)

    if (true){
      console.log(userData.user.id.toString() , userID)

      /////////////////////// NEED TO CHECK IF COORDINATOR IS RESPONSIBLE FOR THIS STUDENT
      belongsToSameSchool( superUserStatus , [userData.user.id.toString() , userID ]).then((result) => {

        console.log('GOOD', result);
        hasAccessRights = result

        if(isSuperUser(userData.user.accountType)){
          hasAccessRights = true
        }

        // Once checks are passed
        if (loggedInCheck && hasAccessRights) {
          const requests = [
            axios.post(axiosURL + '/systemSetting/getOne', { name: 'disable profile page' }),
            axios.post(axiosURL + '/users/userbyid', { userID }),
            axios.post(axiosURL + '/files/studentUserID', { userObjectID: id })
          ]

          Promise.allSettled(requests).then((responses) => {
            const systemSettingRequest = responses[0]
            const userInformationRequest = responses[1]
            const schedulesRequest = responses[2]

            if (
              systemSettingRequest.status === 'rejected' ||
              userInformationRequest.status === 'rejected' ||
              schedulesRequest.status === 'rejected'
            ) {
              history.push('/dashboard')
              notify('e', 'There was an error, please try again')
              return
            }

            // System Setting Request
            if (systemSettingRequest.value.data.success === true && systemSettingRequest.value.data.data.value === 'true') {
              history.push('/accounts')
              notify('e', 'Profile pages are currently not available, try again later')
              return
            }

            // User Information Request
            if (userInformationRequest.value.data._id === userData.user.id) {
              setShowEditIcon(true)
            }
            // *** LOOK HERE: This enables it for coordinator. decide what to do before merging
            setShowEditIcon(true)
            setUser(userInformationRequest.value.data)
            setProfilePictureName(userInformationRequest.value.data.profilePicture)
            
            // Student Schedule Request
            if (schedulesRequest.value.data.success) {
              let returnData = []
              for (const schedule of schedulesRequest.value.data.data) {
                returnData.push({
                  scheduleName: schedule.fileName,
                  owner: schedule.owner,
                  status: [schedule.published, schedule.closed],
                  created: schedule.createdAt,
                  fileID:  schedule._id,
                  rowClick: {fileID: schedule._id, fileName: schedule.fileName}
                })
              }
              setStudentSchedules(returnData)            
            } else {
              notify('e', 'Error getting student files')
              return
            }

            setIsLoading(false)
          })
        }
        else if(loggedInCheck && !hasAccessRights){
          history.push('/dashboard')
        }

      })
      .catch((error) => {
        console.log('BAD', error)
        history.push('/dashboard')
      });
    }

    
  }, [history, id, userData.user.accountType, userData.user.id, userID])

  if (isLoading) {
    return <Loading />
  }

  function handleViewChange(view) {
    if (view === 'about') {
      setAboutBoolean(true)
      setScheduleBoolean(false)

      setAboutBorder(bottomBorderColor)
      setScheduleBorder('')
    } else if (view === 'schedule') {   
      resetFileId()
      setScheduleBoolean(true) // Has to come before next line
      setAboutBoolean(false)

      setAboutBorder('')
      setScheduleBorder(bottomBorderColor)
    }
  }

  function resetFileId() {
    setFileId(null)
  }

  const passwordReset = async (oldPassword, newPassword, newPasswordCheck, userID, email) => {

    if (newPassword != newPasswordCheck){
      setErrorMessage('New password must match')
      console.log(errorMessage)
      return;
    } 

    if (newPassword == '') {
      setErrorMessage('New password is required')
      console.log(errorMessage)
      return;
    }

    /// validate old password -> if valid, then proceed to hashing and saving new password
    const user = {
      email,
      password: oldPassword
    }

    const loginRes = await axios.post(axiosURL + '/users/login', user)
                        .catch(err=> {
                          setErrorMessage('There was an error with the old password, or logging in, please try again later')
                          console.log(errorMessage)
                          console.log(err)
                        })

    if (!loginRes.data.msg){ // no message means worked

      let passwordChangeInfo ={
        newPassword,
        userID
      }

      const passwordRes = await axios.post(axiosURL + '/users/changePassword', passwordChangeInfo)

      console.log(passwordRes)
      if (passwordRes.data.success === true){
        setChangePasswordModal(false)
      }
    }
    else if (loginRes.data.msg){

      setErrorMessage(loginRes.data.msg)
      console.log(errorMessage)
      return;

    }
  }

  function validateAllInputs(firstName, lastName, phone, dateOfBirth, address, studentID, email, schoolYear){
    firstName = firstName.trim()
    lastName = lastName.trim()
    phone = phone.trim()
    studentID = studentID.trim()
    email = email.trim()
    schoolYear = schoolYear.trim()


    if (isValidName(firstName) == false){
      setErrorMessage('Issue with first name')
    }
    else if (isValidName(lastName) == false){
      setErrorMessage('Issue with last name')
    }
    else if (isValidStudentID(studentID)[0] == false){
      errorMessage = (isValidStudentID(studentID)[1])
    }
    else if (isValidEmail(email) == false){
      setErrorMessage('Issue with email address')
    }
    else if (isValidPhone(phone) == false){
      setErrorMessage('Issue with phone number')
    }
    else if (isValidAge(dateOfBirth) == false){
      setErrorMessage('Issue with date of birth or age')
    }
    else if (isValidAddress(address) == false){
      setErrorMessage('Issue with address selected')
    }
    else if (isValidSchoolYear(schoolYear) == false){
      setErrorMessage('Issue with school year selected')
    }
    

    if (errorMessage != ''){
      notify('e',errorMessage)
      setErrorMessage('')
    }
    else{
      handleEditPersonalInfo(firstName, lastName, phone, dateOfBirth, address, studentID, email, schoolYear)
    }
  }

  function handleEditPersonalInfo(firstName, lastName, phone, dateOfBirth, address, studentID, email, schoolYear)  {

      const packagedData = {
        userId: id,
        firstName,
        lastName,
        phone,
        dateOfBirth,
        address,
        studentID,
        email,
        schoolYear
      }

      socket.emit('EditUserInfoRequest', packagedData)
  }

  function PersonalInfoModal(props) {

    const [phone, setPhone] = useState(user.studentInfo.phone)
    const [address, setAddress] = useState(user.studentInfo.address)
    const [dob, setDob] = useState(notNullString(user.studentInfo.dateOfBirth) ? convertTime(user.studentInfo.dateOfBirth) : null)
    
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)

    const [studentId, setStudentId] = useState(user.studentInfo.studentID)
    const [email, setEmail] = useState(user.email)

    const studentYearOptions = [ "Freshman", "Sophomore", "Junior", "Senior", "Other" ]
    const [schoolYear, setSchoolYear] = useState(user.studentInfo.schoolYear)


    return (
      <Modal {...props} 
      size="lg" 
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      contentClassName={!isMobile ? '' : 'modalForMobile'}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Update Personal Info</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px 50px !important' }}>
          <p style={{ textAlign: 'center' }}>
            {isStudent(userData.user.accountType) != true && (
              <TextField
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
                className={isMobile ? classes.mobileMargin : classes.Left}
                id="firstName"
                label="First Name"
              />
            )}

            {isStudent(userData.user.accountType) != true && (
              <TextField
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
                className={isMobile ? classes.mobileMargin : classes.Right}
                id="lastName"
                label="Last Name"
              />
            )}

            {isStudent(userData.user.accountType) != true && (
              <TextField
                value={studentId}
                onChange={(e) => {
                  setStudentId(e.target.value)
                }}
                className={isMobile ? classes.mobileMargin : classes.Left}
                id="studentID"
                label="Student ID"
              />
            )}

            {isStudent(userData.user.accountType) != true && (
              <TextField
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                className={isMobile ? classes.mobileMargin : classes.Right}
                id="email"
                label="E-Mail"
              />
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                value={dob}
                onChange={setDob}
                renderInput={(params) => <TextField {...params} className={isMobile ? classes.mobileMargin : classes.Left} />}
              />
            </LocalizationProvider>

            <MuiPhoneNumber
              label="Phone Number"
              fullWidth
              defaultCountry={'us'}
              variant="outlined"
              style={{ marginTop: '10px' }}
              onChange={setPhone}
              value={phone}
              disableAreaCodes
              countryCodeEditable={false}
              className={isMobile ? classes.mobileMargin : classes.Right}
            />

            {
              <FormControl fullWidth className={classes.Left}>
              <TextField
                style={{float: 'left'}}
                variant='outlined'
                select 
                value={schoolYear}
                required
                label={"School Year"}
                id={'School Year'}
                onChange={(e) => {setSchoolYear(e.target.value)}}
                fullWidth
              >
                <MenuItem value={''}>&nbsp;</MenuItem>
                  {studentYearOptions != null &&
                    studentYearOptions.map(option => {
                      return <MenuItem value={option}>{option}</MenuItem>
                    })
                  }
              </TextField>
              </FormControl>
            }

            <AddressAutocomplete value={address} style={{ marginTop: '10px' }} onChange={setAddress} />

            {isStudent(userData.user.accountType) == true && (
              <div>
                <br></br>* If you need to update your Student ID, email, or name, please contact your coordinator!
              </div>
            )}

            {/* {alert != '' &&
            <Alert className={classes.Alert} severity="error">{alert}</Alert>  
          }               */}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton
            onClick={() => {
              validateAllInputs(firstName, lastName, phone, dob, address, studentId, email, schoolYear)
              props.onHide()
            }}
          >
            Save
          </AppointBlueButton>
        </Modal.Footer>
      </Modal>
    )
  }

  function ChangePasswordModal(props) {

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordCheck, setNewPasswordCheck] = useState('')
    const [showPasswordOld, setShowPasswordOld] = useState(false)
    const [showPasswordNew, setShowPasswordNew] = useState(false)


    return (
      <Modal {...props} 
      size="medium" 
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      contentClassName={!isMobile ? '' : 'modalForMobile'}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px 50px !important' }}>
          <p style={{ textAlign: 'center' }}>
            {isStudent(userData.user.accountType) == true && (
              <TextField
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value)
                }}
                className={classes.mobileMargin}
                id="oldPassword"
                label="Old Password"
                type={showPasswordOld ? 'text' : 'password'}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordOld(!showPasswordOld)}
                            edge="end"
                        >
                            {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
              />
            )}

            {isStudent(userData.user.accountType) == true && (
              <TextField
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                className={classes.mobileMargin}
                id="newPassword"
                label="New Password"
                type={showPasswordNew ? 'text' : 'password'}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                            edge="end"
                        >
                            {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
              />
            )}

            {isStudent(userData.user.accountType) == true && (
              <TextField
                value={newPasswordCheck}
                onChange={(e) => {
                  setNewPasswordCheck(e.target.value)
                }}
                className={classes.mobileMargin}
                id="newPasswordCheck"
                label="Confirm New Password"
                type={showPasswordNew ? 'text' : 'password'}
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                            edge="end"
                        >
                            {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
              />
            )}

            {errorMessage != '' &&
            <Alert className={classes.Alert} severity="error">{errorMessage}</Alert>  
            }              
          </p>
        </Modal.Body>
        <Modal.Footer>
          <AppointGreyButton onClick={props.onHide}>Cancel</AppointGreyButton>
          <AppointBlueButton
            onClick={() => {
              passwordReset(oldPassword, newPassword, newPasswordCheck, userID, user.email)
              // if (errorMessage == '' || errorMessage == null) {
              //   props.onHide()
              // }
            }}
          >
            Save
          </AppointBlueButton>
        </Modal.Footer>
      </Modal>
    )
  }


  function MainContent() {
    if (aboutBoolean === true) {
      if (!isMobile) {
        return (
          <div>
            <div className="userProfileAboutSection">
              <div className="userProfileBasicInformation">
                <div className="userProfileTitle">Basic Information</div>
                <div className="userProfileInformationCard">
                  <div>
                    <span className="userProfileInformationDescription">Name</span>
                    <span className="userProfileInformation">
                      {user.firstName} {user.lastName}
                    </span>
                  </div>
                  <span className="userProfileInformationDescription">Student ID</span>
                  <span className="userProfileInformation">
                    {console.log(user)}
                    {notNullString(user.studentInfo.studentID) ? user.studentInfo.studentID : getNA()}
                  </span>
                  <span className="userProfileInformationDescription">Age</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? calculateAge(convertTime(user.studentInfo.dateOfBirth)) : getNA()}{' '}
                  </span>
                  <span className="userProfileInformationDescription">Date of Birth</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? convertTime(user.studentInfo.dateOfBirth) : getNA()}{' '}
                  </span>
                  <span className="userProfileInformationDescription">School Year</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.schoolYear) ? user.studentInfo.schoolYear : getNA()}
                  </span>
                  {/* <span className="userProfileInformationDescription">License Plate</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.licensePlate) ? user.studentInfo.licensePlate : getNA()}{' '}
                  </span> */}
                </div>
              </div>

              <div className="userProfileContactInformation">
                <div className="userProfileTitle">Contact Information</div>
                <div className="userProfileInformationCard">
                  <span className="userProfileInformationDescription">Email</span>
                  <span className="userProfileInformation">{user.email}</span>
                  <span className="userProfileInformationDescription">Phone</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.phone) ? formatPhoneNumber(user.studentInfo.phone) : getNA()}
                  </span>
                  <span className="userProfileInformationDescription">Address</span>
                  <span className="userProfileInformation">
                    {notNullString(user.studentInfo.address.address) ? user.studentInfo.address.address : getNA()}
                  </span>
                </div>
              </div>
            </div>
            <AppointBlueButton style={{marginTop: '20px'}} onClick={() => setPersonalInfoModal(true)}>Edit Profile</AppointBlueButton>
            
            {isStudent(userData.user.accountType) == true && (
              <AppointBlueButton style={{marginTop: '20px', marginLeft: '10px'}} onClick={() => setChangePasswordModal(true)}>Change Password</AppointBlueButton>
            )}
            
            
            {/* {isStudent(userData.user.accountType) != true && (
              <TextField
                value={studentId}
                onChange={(e) => {
                  setStudentId(e.target.value)
                }}
                className={isMobile ? classes.mobileMargin : classes.Left}
                id="studentID"
                label="Student ID"
              />
            )} */}
            
            <PersonalInfoModal
              show={personalInfoModal} 
              onHide={() => { setPersonalInfoModal(false) }} 
            />

            <ChangePasswordModal
              show={changePasswordModal} 
              onHide={() => { setChangePasswordModal(false) }} 
            />

          </div>
        )
      } else {
        // mobile:

        return (
          <div>
            <div>
              <div className="userProfileTitle">Basic Information</div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Name
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                 Student ID
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.studentID) ? user.studentInfo.studentID : getNA()}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Age
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? calculateAge(convertTime(user.studentInfo.dateOfBirth)) : getNA()}{' '}
                  </div>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Date of Birth
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.dateOfBirth) ? convertTime(user.studentInfo.dateOfBirth) : getNA()}{' '}
                  </div>
                </div>
              </div>

              {/* <div className="mobile_rows">
                <div className="mobile_Columns">
                  <div>
                    <b>License Plate</b>
                  </div>
                </div>
                <div className="mobile_Columns">
                  <div className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.licensePlate) ? user.studentInfo.licensePlate : getNA()}{' '}
                  </div>
                </div>
              </div> */}
            </div>

            <div>
              <div className="userProfileTitle">Contact Information</div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Phone
                </div>
                <div className="mobile_Columns">
                  <span className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.phone) ? formatPhoneNumber(user.studentInfo.phone) : getNA()}{' '}
                  </span>
                </div>
              </div>

              <div className="mobile_rows">
                <div className="mobile_Columns">
                  Address
                </div>
                <div className="mobile_Columns">
                  <span className="mobile_userProfileInformation">
                    {notNullString(user.studentInfo.address.address) ? user.studentInfo.address.address : getNA()}{' '}
                  </span>
                </div>
              </div>

              <div className="mobile_rows">
                  Email
                <div className="mobile_emailText">{user.email}</div>
              </div>
            </div>

            <AppointBlueButton style={{marginTop: '20px'}} onClick={() => setPersonalInfoModal(true)}>Edit Profile</AppointBlueButton>
            {isStudent(userData.user.accountType) == true && (
              <AppointBlueButton style={{marginTop: '20px', marginLeft: '10px'}} onClick={() => setChangePasswordModal(true)}>Change Password</AppointBlueButton>
            )}
            
            <PersonalInfoModal
              show={personalInfoModal} 
              onHide={() => { setPersonalInfoModal(false) }} 
            />

            <ChangePasswordModal
              show={changePasswordModal} 
              onHide={() => { setChangePasswordModal(false) }} 
            />

          </div>
        )
      }
    } else if (scheduleBoolean === true) {

      function handleRowClick(e) {
        setFileId(e.rowClick.fileID)
      }
      
      return (
        <>
          {!fileId ?
            <Table title={'Schedules'} type='general' columns={scheduleColumns} data={studentSchedules} handleRowClick={handleRowClick} />
          :
            <>
              <AppointTransparentButton style={{marginBottom: '10px'}} onClick={resetFileId}>
                <img src={config.backButton} alt='Back Button' style={{width: '20px', height: '20px'}} /> Back to Schedules
              </AppointTransparentButton>
              <StudentFile fileIdOverride= {fileId} studentIdOverride={id} />
            </>
          }
        </>
        
      )
    }
  }

  function ProfilePhotoUploader(props) {
    const [file, setFile] = useState(null)
    const [photoURL, setPhotoURL] = useState(null)
    const [isTosCollapsed, setIsTosCollpased] = useState([])

    const handleChange = (e) => {
      const file = e.target.files[0]
      if (file) {
        setFile(file)
        setPhotoURL(URL.createObjectURL(file))
      }
    }

    const handleCancel = () => {
      setFile(null)
      setPhotoURL(null)
    }

    const handleSubmit = async (submitFile, url) => {
      let newFile = await new File([submitFile], 'test.jpeg', { type: 'image/jpeg', encoding: '7bit' })
      newFile.originalName = 'file.jpeg'

      // https://stackoverflow.com/questions/57265654/how-to-convert-image-data-to-multipart-form-data
      let formData = await new FormData()
      await formData.append('file', newFile)
      await formData.append('userId', userID)

      axios
        .post(axiosURL + '/photoUpload/upload', formData, { headers: { 'content-type': 'multipart/form-data;boundary=${submitFile.boundary}' } })
        .then((res) => {
          if (res.data.success) {
            setProfilePictureName(res.data.file.filename)
            // If current user, we are going to adjust the application nav
            if (userData.user.id == userID) {
              adjustNavProfilePhoto(res.data.file.filename)
            }
            notify('s', 'Photo Uploaded')
          } else {
            notify('e', 'There was an error, refresh page and try again')
          }
          setFile(null)
          setPhotoURL(null)
          props.onHide()
        })
    }

    const handleDelete = () => {
      if (profilePictureName == null) return

      const packagedData = {
        userID,
        fileName: profilePictureName,
      }
      axios.post(axiosURL + '/photoUpload/delete', packagedData).then((res) => {
        if (res.data.success) {
          setProfilePictureName(null)
          // If current user, we are going to adjust the application nav
          if (userData.user.id == userID) {
            adjustNavProfilePhoto(null)
          }

          setFile(null)
          setPhotoURL(null)
          props.onHide()
          notify('s', 'Photo Deleted')
        } else {
          props.onHide()
          notify('e', 'There was an error, refresh page and try again')
        }
      })
    }

    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName={!isMobile ? '' : 'modalForMobile'}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Photo Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center' }}>
            {!file ? (
              <>
                <div>
                  <label htmlFor="profilePhoto">
                    <input accept="image/*" id="profilePhoto" type="file" style={{ display: 'none' }} onChange={handleChange} />
                    <span class="deletebuttongreen noselect">
                      <span class="text">Upload Photo</span>
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path
                            class="checkmark__check"
                            d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </label>
                </div>
                &nbsp;
                <div>
                  <label>
                    <span class="deletebutton noselect" onClick={handleDelete}>
                      <span class="text">Delete Photo</span>
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                        </svg>
                      </span>
                    </span>
                  </label>
                </div>
                &nbsp;
                <p className={classes.LegalNotice}>By uploading an image, you acknowledge and agree to our terms of use:</p>
              </>
            ) : (
              <CropEasy {...{ photoURL, setPhotoURL, setFile, handleSubmit, handleCancel }} />
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Collapse accordion={true} activeKey={isTosCollapsed} onChange={setIsTosCollpased}>
            <Collapse.Panel header={<div className="viewTOSbutton">View Terms of Service</div>} key="1">
              <div>
                <p>
                  1. By uploading a photo to your profile, you confirm that you own the rights to the photo or have obtained permission from the
                  copyright holder to use it.
                  <br></br>
                  <br></br>
                  2. You agree that the photo will not contain any offensive or illegal content, including but not limited to hate speech, sexually
                  explicit material, or violence.
                  <br></br>
                  <br></br>
                  3. You grant the website the right to use, display, and distribute the photo in connection with your profile and the website's
                  services.
                  <br></br>
                  <br></br>
                  4. The website reserves the right to remove or disable access to any photo that violates these terms and conditions or is otherwise
                  deemed inappropriate.
                  <br></br>
                  <br></br>
                  5. You agree to indemnify and hold harmless the website and its affiliates from any claims arising out of or related to your use of
                  the photo.
                  <br></br>
                  <br></br>
                  6. These terms and conditions may be updated from time to time, and your continued use of the website after any changes indicates
                  your acceptance of the new terms.
                  <br></br>
                  <br></br>
                  7. You are responsible for ensuring that your photo does not infringe any third-party rights, including but not limited to
                  intellectual property rights, privacy rights, and personality rights.
                  <br></br>
                  <br></br>
                  8. Any disputes arising out of or related to this agreement or your use of the website will be resolved in accordance with the laws
                  of the jurisdiction in which the website is headquartered.
                  <br></br>
                  <br></br>
                  9. By uploading a photo, you confirm that you have read and understand these terms and conditions and agree to be bound by them.
                </p>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <ProfilePhotoUploader
        show={openProfilePhotoSelector}
        onHide={() => {
          setOpenProfilePhotoSelector(false)
        }}
      />

      <AppointTileWrapper className="userProfile">
        <div className={isMobile ? 'Mobile_userProfilePictureSection' : 'userProfilePictureSection'}>
          <div className={isMobile ? 'center' : ''}>  
            <ProfilePicture fileName={profilePictureName} size={'xlarge'} boxShadow={PlatformStyles.BoxShadow} />
            {showEditIcon && <EditOutlined onClick={() => setOpenProfilePhotoSelector(true)} className={classes.EditIcon} />}
          </div>
        </div>

        <div className={'userProfilePictureSection'}>
          <div>
            <div className={isMobile ? 'center' : 'userProfileNameAddress'}>
              <div className="userProfileName">
                {user.firstName} {user.lastName}
              </div>
              <div className="userProfileYear">{user.studentInfo.classYear}</div>
            </div>
          </div>
        </div>

        <div className="userProfileViewSelector">
          <button className="userProfileButton" onClick={() => handleViewChange('about')} style={{ borderBottom: aboutBorder }}>
            About
          </button>

          {userData.user.accountType !== 'student' && (
            <button className="userProfileButton" onClick={() => handleViewChange('schedule')} style={{ borderBottom: schedulBorder }}>
              Schedules
            </button>
          )}
        </div>

        <div className="userProfileMainContent">
          <MainContent />
        </div>
      </AppointTileWrapper>
    </>
  )
}

const scheduleColumns = [
  {
      title: "Schedule Name",
      field: "scheduleName",
      headerStyle: {paddingLeft: '30px'},
      cellStyle: {paddingLeft: '30px'}
  },
  {
      title: "Status",
      field: "status",
      render: (rowData) => <div>{StudentFilePills(rowData.status[0], rowData.status[1])}</div>,
  },
  {
      title: "Created",
      field: "created",
  },
  {
    title: "Owner",
    field: "owner",
  }
];
