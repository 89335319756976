import {
  HomeOutlined, InsertRowAboveOutlined, LogoutOutlined
} from '@ant-design/icons';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import config from '../../config';
import UserContext from '../../context/UserContext';
import About from '../About/About';
import ContactUs from '../ContactUs/ContactUs';
import Home from '../Home/Home';
import Login from '../Login/Login';
import PasswordResetRequest from '../Login/PasswordResetRequest';
import { AppointLogo } from '../misc/AppointLogo';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import Products from '../Products/Products';
import Activate from '../Register/Activation';
import './Navbar.css';
import ErrorPage from '../ErrorBoundary/ErrorPage';

export default function Navbar() {
    /* Using useHistory imported above to redirect to links */
    const history = useHistory();
    const defaultHome = () => history.push('/');
    const home = () => {
        if(window.location.href.split("/").at(-1) === ""){
            window.scroll({top: 0,  behavior: "smooth" })
        } else {
            console.log("We were not on the home page")
        }
        history.push('/')
    }
    const about = () => history.push('/about');
    const products = () => history.push('/products');
    const dashboard = () => history.push('/dashboard');
    const login = () => history.push('/login');
    
    const [entireBarColor, setEntireBarColor] = useState({backgroundColor: 'transparent'})
    const [entireBarHeight, setEntireBarHeight] = useState({height: '0px'})
    const [logoColor, setLogoColor] = useState({color: 'white'})
    const [itemColor, setItemColor] = useState({color: 'white'})
    
    const [drawerOpen, setDrawerOpen] = useState(false)
    
    useLayoutEffect(() => {
        let x = window.location.href.split('/')[window.location.href.split('/').length - 1]
        if(x !== 'home' && x !== ''){
            setEntireBarHeight({height: '68px'})
        } else {
            setEntireBarHeight({height: '0px'})
        }
    }, [window.location.href])

    // Took out the register page but leaving path here for now
    // const register = () => history.push('/register');
    const contactus = () => history.push('/contactus');
    const signout = () => {
        localStorage.setItem("auth-token", ""); /* Removes token from website cache*/
        setUserData({ /* resets token stuff in program */
            token:undefined,
            user: undefined
        })
        history.push('/login')
    }
    /* Pulls in context from app.js */
    const {userData, setUserData, isMobile} = useContext(UserContext)

    const entireBar = useRef()

    function handleNavColor(bool){
        // If you are anywhere but the homepage
        let x = window.location.href.split('/')
        
        // Bar appears if true (or not home page), dissapears if false
        if (bool || x === 'home' || x === '') {
            // setEntireBarColor({backgroundColor: 'rgb(54, 74, 94)'})
            setEntireBarHeight({height: '68px'})
        } else {
            setEntireBarColor({backgroundColor: 'transparent'})
            setEntireBarHeight({height: '0px'})
        }        
    }

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen)
    }

    const MobileList = (anchor) => (
        <Box
          sx={{ width: '80vw', maxWidth: '400px'}}
          role="presentation"
        >
          <List>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <AppointLogo blackVariant={true} />
            </div>
            <Divider />

            <ListItem key={"Home"} disablePadding onClick={() => {toggleDrawer(); home()}}>
                <ListItemButton>
                <ListItemIcon>
                    <HomeOutlined className='menuItemIcon' />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
                </ListItemButton>
            </ListItem>
            {userData.user ? 
                <>
                    <ListItem key={"Dasboard"} disablePadding onClick={() => {toggleDrawer(); dashboard()}}>
                        <ListItemButton>
                            <ListItemIcon>
                                <InsertRowAboveOutlined className='menuItemIcon' />
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItemButton>
                    </ListItem> 
                    <ListItem key={"SignOut"} disablePadding onClick={() => {toggleDrawer(); signout()}}>
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutOutlined className='menuItemIcon' />
                            </ListItemIcon>
                            <ListItemText primary={"Sign out"} />
                        </ListItemButton>
                    </ListItem>
                </>
                :
                <ListItem key={"Login"} disablePadding onClick={() => {toggleDrawer(); login()}}>
                    <ListItemButton>
                        <ListItemIcon>
                            <InsertRowAboveOutlined className='menuItemIcon' />
                        </ListItemIcon>
                        <ListItemText primary={"Login"} />
                    </ListItemButton>
                </ListItem>
            }

            <Divider />
            <ListItem key={'Close'} disablePadding onClick={toggleDrawer}>
                <ListItemButton>
                  <ListItemIcon>
                    <HighlightOffIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Close'} />
                </ListItemButton>
              </ListItem>
            </List>
          {/* Leaving this here since the divider is a good visual that we can use. Uncomment to see */}
          {/* <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Box>
      );

    return(
        <div>
            <div className='entireBarTest' style={entireBarHeight}>
            </div>
            <div className='entireBar' ref={entireBar} style={entireBarColor}>
                <nav className='NavbarItems'>
                  <div style={{ marginLeft: '30px'}}>
                    <AppointLogo />
                  </div>

                    {/* Navbar menu for mobile */}
                    <div>
                        <React.Fragment>
                            <Drawer
                                anchor={'right'}
                                open={drawerOpen}
                                onClose={toggleDrawer} // Closes the drawer if you click outside the window
                                >
                                {/* {MobileList('left')} */}
                                {MobileList('right')}
                            </Drawer>
                        </React.Fragment>
                    </div>

                    {/* Variables and styling for each item in the nav bar can be found in MenuItems.js */}
                    <ul className='nav-menu' style={itemColor}>
                        {isMobile ? 
                            <>
                            <img src={config.HamburgerIcon} className='HamburgerIcon' onClick={toggleDrawer}/>
                            </>
                        :
                            <>
                                <li key='home' className='nav-links' onClick={home}>Home</li>
                                {/* If the user exists, then we show different data */}
                                { userData.user ? 
                                    <>
                                        <li key='dashboard' className='nav-links' onClick={dashboard}>Dashboard</li>
                                        <li key='signout' className='nav-links' onClick={signout}>Sign out</li>
                                    </>
                                    : 
                                    <li key='login' className='nav-links' onClick={login}>Login</li>
                                    // <li key='register' className='nav-links' onClick={register}>Register</li>,
                                    // <li key='contactus' className='nav-links' onClick={contactus}>Contact Us</li>
                                }
                            </>
                            }
                        
                    </ul>
                </nav>
            </div>
            
            <div className='NavBarContent'>
                {/* Depending on the route, it sends you to that page */}
                <Switch>
                    {/* <Route exact path='/' render={(props)=> <Home />}/> */}
                    <Route exact path='/' render={(props)=> <Home  handleNavColor={(bool) => handleNavColor(bool)} />}/>
                    <Route exact path='/home'  render={(props)=> <Home handleNavColor={(bool) => handleNavColor(bool)} />}/>
                    <Route exact path='/about' render={(props)=> <About />}/>
                    <Route exact path='/products' render={(props)=> <Products />}/>
                    <Route exact path='/login' render={(props)=> <Login />}/>
                    {/* <Route exact path='/resetrequest' render={(props)=> <PasswordResetRequest />}/> */}
                    <Route exact path='/resetpassword/:token' render={(props)=> <PasswordResetRequest />}/>
                    <Route exact path='/activate/:token' render={(props)=> <Activate />}/>
                    {/* <Route exact path='/register' render={(props)=> <Register />}/> */}
                    <Route exact path='/contactus' render={(props)=> <ContactUs />}/>
                    <Route exact path='/error' render={(props)=> <ErrorPage />}/>
                    <Route render={(props)=> <NotFoundPage />}/>
                </Switch>
            </div>
            

        </div>
    )
}

